import { FC, HTMLAttributes, ReactNode } from 'react';
import Container from '@mui/material/Container';

import { Body, Content } from './style';
import { HeaderMenu } from './components';

export interface AdminWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export const AdminWrapper: FC<AdminWrapperProps> = ({ children }) => (
  <Body>
    <Content>
      <HeaderMenu />
      <Container disableGutters>{children}</Container>
    </Content>
  </Body>
);
