import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button, { ButtonProps } from '@mui/material/Button';

import { ReactComponent as AppBarLogo } from '../../../shared/assets/images/da-admin.svg';
import { Colors } from '../../../shared/constanst/colors';

export const HeaderContainer = styled('div')`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;

  background-color: #f2f1f4;

  @media (max-width: 767px) {
    padding-bottom: 8px;
  }

  @media (min-width: 768px) {
    padding-bottom: 16px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 20px;
  }

  @media (min-width: 1366px) {
    padding-bottom: 20px;
  }
`;

export const AppBarLogoStyled = styled(AppBarLogo)({
  width: '99px',
  marginRight: '79px',
});

export const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  marginLeft: '12px',
  [theme.breakpoints.up('md')]: {
    minHeight: '76px',
  },
}));

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  borderBottom: '1px solid #d8dcde',
  boxShadow: '0 0 10px 0 rgb(23 73 77 / 15%)',
  backgroundColor: '#fff',
  padding: '0 4px',
  color: '#68737d',
  fontSize: '14px',

  borderRadius: '0 0 16px 16px',
  height: '76px',

  [theme.breakpoints.down('md')]: {
    height: '56px',
  },

  '& .MuiButton-root': {
    display: 'inline-flex',
  },
}));

interface ButtonStyledProps extends ButtonProps {
  isActive?: boolean;
}

export const ButtonStyled = styled(Button, {
  shouldForwardProp: prop => prop !== 'isActive',
})<ButtonStyledProps>(({ isActive }) => ({
  display: 'inline-flex',
  color: Colors.black,
  fontSize: '14px',
  textTransform: 'none',
  marginRight: '12px',
  fontFamily: 'Proxima Nova, sans-serif',

  '&:hover, &:focus': {
    color: Colors.green,
    background: 'transparent',
  },

  ...(isActive && {
    color: Colors.green,
  }),
}));
