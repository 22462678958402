import { FC, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  DataGrid,
  GridRowParams,
  GridActionsCellItem,
  GridRowId,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Container, Tooltip, Chip } from '@mui/material';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

import { useStore, getLink } from '../../../../../../../shared/utils';
import {
  EOrganizationStatus,
  TypeOrganizationStatus,
} from '../../../../../../../../api/models/organization.model';
import { OrganizationsController } from '../../../../../../../controllers/organizations.controller';
import { OrganizationsStore } from '../../../../../../../stores/organizations/organizations.store';
import { FilterStore } from '../../../../../../../stores/organizations/filter.store';
import { getColumns, getRows } from '../../../../utils';
import { AdminRoutes, ERegexp } from '../../../../../../routes';

export const OrganizationsTable: FC = observer(() => {
  const history = useHistory();

  const { fetchOrganizations, setPage } = useStore(OrganizationsController);
  const { organizations, page, totalPages, isLoading } = useStore(OrganizationsStore);
  const {
    filters: { userId, status, nameOrInn, creationDate },
  } = useStore(FilterStore);

  useEffect(() => {
    fetchOrganizations();
  }, [page, userId, status, nameOrInn, creationDate]);

  const handleMoreOrganization = useCallback(
    (id: GridRowId) => () => {
      history.push(getLink(AdminRoutes.OrganizationItem, { [id]: ERegexp.ORGANIZATIONID }));
    },
    []
  );

  const renderActions = (param: GridRowParams) => {
    return [
      <Tooltip title="Подробнее">
        <GridActionsCellItem
          icon={<ReadMoreIcon />}
          label="Подробнее"
          onClick={handleMoreOrganization(param.id)}
        />
      </Tooltip>,
    ];
  };

  const renderStatus = (params: GridRenderCellParams<TypeOrganizationStatus>) => {
    if (!params.value) {
      return <span />;
    }

    const color = params.value.statusId === EOrganizationStatus.ACTIVE ? 'primary' : 'error';
    return <Chip label={params.value.statusName} color={color} />;
  };

  const rows = useMemo(() => getRows(organizations), [organizations]);

  type Row = typeof rows[number];

  const columns = useMemo<GridColumns<Row>>(() => getColumns(renderActions, renderStatus), [
    organizations,
  ]);

  return (
    <Container disableGutters>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
          disableSelectionOnClick
          hideFooterSelectedRowCount
          disableColumnMenu
          columns={columns}
          rows={rows}
          pagination={true}
          paginationMode={'server'}
          onPageChange={param => setPage(param)}
          loading={isLoading}
          rowsPerPageOptions={[20]}
          page={page}
          pageSize={20}
          rowCount={10 * totalPages}
        />
      </div>
    </Container>
  );
});
