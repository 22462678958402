import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthorizationStore } from '../stores/authorization/authorization.store';
import { useStore } from '../shared/utils';
import { SpinnerLoaderWrapper, SpinnerLoader } from '../shared/styles/dotsLoader';
import { ApplicationRoutes } from '../routes';

import { AdminRoutes } from './routes';
import { MainPage, NewsRouter, UsersRouter, OrganizationsRouter, AlertsRouter } from './pages';
import { AdminWrapper } from './AdminWrapper';

export const AdminRouter: FC = observer(() => {
  const { checkIsUserLoginned, isLoggedOut, isLoading, isLoaded, checkUser } = useStore(
    AuthorizationStore
  );

  useEffect(() => {
    checkIsUserLoginned();
  }, []);

  if (isLoggedOut) {
    return <Redirect to={ApplicationRoutes.Authorization} />;
  }

  if (isLoading || !checkUser) {
    return (
      <SpinnerLoaderWrapper>
        <SpinnerLoader data-test-id="dots-loader">
          <span />
        </SpinnerLoader>
      </SpinnerLoaderWrapper>
    );
  }

  return (
    <>
      {isLoaded && checkUser && (
        <AdminWrapper>
          <Switch>
            <Route path={AdminRoutes.News} component={NewsRouter} />
            <Route path={AdminRoutes.Users} component={UsersRouter} />
            <Route path={AdminRoutes.Organizations} component={OrganizationsRouter} />
            <Route path={AdminRoutes.Alerts} component={AlertsRouter} />
            <Route path={AdminRoutes.Main} component={MainPage} />
            <Redirect to={AdminRoutes.Main} />
          </Switch>
        </AdminWrapper>
      )}
    </>
  );
});
