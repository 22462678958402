import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { Colors } from '../../constants/colors';

export const Body = styled(Box)({
  backgroundColor: Colors.backgroundSecondaryGray,
});

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: 'auto',
  lineHeight: 1.4,
  color: Colors.black,
  fontSize: 14,

  [theme.breakpoints.down('sm')]: {
    paddingTop: 64,
  },

  [theme.breakpoints.up('sm')]: {
    paddingTop: 92,
  },

  [theme.breakpoints.up('md')]: {
    paddingTop: 96,
  },

  [theme.breakpoints.up('xl')]: {
    paddingTop: 96,
  },
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 24,
  background: Colors.white,
  borderRadius: 16,

  [theme.breakpoints.down('sm')]: {
    marginBottom: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: 16,
    paddingBottom: 20,
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: 20,
    paddingBottom: 20,
  },
}));

export const MainBody = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderTop: '1px solid',
  borderColor: Colors.darknessGray,
  marginTop: 16,

  [theme.breakpoints.down('sm')]: {
    padding: '20px 16px 0 16px',
  },

  [theme.breakpoints.up('sm')]: {
    padding: '24px 24px 0 24px',
  },
}));

export const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 24,
  paddingRight: 24,
});

export const TopSection = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const H1 = styled('h1')({
  margin: 0,
  padding: 0,
  color: Colors.black,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: '28px',
});

export const H2 = styled('h2')({
  margin: 0,
  padding: 0,
  color: Colors.black,
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '28px',
});
