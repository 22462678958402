export enum ERegexp {
  USERID = ':userId',
  NEWSID = ':newsId',
  ORGANIZATIONID = ':organizationId',
  ALERTID = ':alertId',
  CHECKLISTID = ':checkListId'
}

export enum AdminRoutes {
  Main = '/',
  News = '/news',
  NewsItem = `/news/:newsId`,
  NewsCreate = '/news/create',
  NewsEdit = `/news/:newsId/edit`,
  NewsDelete = '/news/:newsId/delete',
  Users = '/users',
  UserItem = '/users/:userId',
  UserCreate = '/users/create',
  UserEdit = '/users/:userId/edit',
  UserDelete = '/users/:userId/delete',
  UserOrganizations = '/users/:userId/organizations',
  UserNews = '/users/:userId/news',
  UserJournal = '/users/:userId/journal',
  EditExpertises = '/users/:userId/edit-expertises',
  EditInterests = '/users/:userId/edit-interest',
  Services = '/services',
  Calculator = '/calculator',
  Dictionary = '/dictionary',
  Organizations = '/organizations',
  OrganizationItem = '/organizations/:organizationId',
  OrganizationRequisites = '/organizations/:organizationId/requisites',
  OrganizationEmployes = '/organizations/:organizationId/employes',
  OrganizationInvites = '/organizations/:organizationId/invites',
  OrganizationNews = '/organizations/:organizationId/news',
  OrganizationFields = '/organizations/:organizationId/fields',
  OrganizationRoles = '/organizations/:organizationId/roles',
  Alerts = '/alerts',
  AlertTask = '/alerts/:alertId/task/information',
  CheckListTask = '/alerts/:checkListId/checkList/information',
}
