import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { isEmpty, has } from 'lodash';
import {
  DataGrid,
  GridRowParams,
  GridActionsCellItem,
  GridRowId,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Container, Tooltip, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { useStore, getLink } from '../../../../../../../shared/utils';
import {
  EUserAdminStatus,
  initialUserAdminStatus,
  TypeUserAdminRole,
  initialUserAdminRole,
} from '../../../../../../../../api/models/user.admin.model';
import { UsersController } from '../../../../../../../controllers/users.controller';
import { UsersStore } from '../../../../../../../stores/users/users.store';
import { FilterStore } from '../../../../../../../stores/users/filter.store';
import { getColumns, getRows } from '../../../../utils';
import { AdminRoutes, ERegexp } from '../../../../../../routes';
import { BlockUserDialog } from '../BlockUserDialog/BlockUserDialog';

export const UsersTable: FC = observer(() => {
  const [showBlockUserModal, setShowBlockUserModal] = useState<boolean>(false);
  const [showUnlockUserModal, setShowUnlockUserModal] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState<string>('');

  const history = useHistory();

  const { fetchUsers, setPage } = useStore(UsersController);
  const { users, page, totalPages, isLoading } = useStore(UsersStore);
  const { filters } = useStore(FilterStore);
  const { userId, fullName, phoneNum, email, regDateTo, roles, statuses, genders } = filters;

  useEffect(() => {
    fetchUsers();
  }, [page, userId, fullName, phoneNum, email, regDateTo, roles, statuses, genders]);

  const handleEditUser = useCallback(
    (id: GridRowId) => () => {
      history.push(getLink(AdminRoutes.UserEdit, { [id]: ERegexp.USERID }));
    },
    []
  );

  const handleBlockUser = useCallback(
    (id: string) => () => {
      setCurrentUserId(id.toString());
      setShowBlockUserModal(true);
    },
    []
  );

  const handleUnlockUser = useCallback(
    (id: string) => () => {
      setCurrentUserId(id.toString());
      setShowUnlockUserModal(true);
    },
    []
  );

  const handleCloseBlockUserModal = () => {
    setShowBlockUserModal(false);
  };

  const handleCloseUnlockUserModal = () => {
    setShowUnlockUserModal(false);
  };

  const renderActions = (param: GridRowParams) => {
    return [
      <Tooltip
        title={param.row.status === EUserAdminStatus.ACTIVE ? 'Заблокировать' : 'Разблокировать'}
      >
        <GridActionsCellItem
          icon={param.row.status === EUserAdminStatus.ACTIVE ? <LockIcon /> : <LockOpenIcon />}
          label={param.row.status === EUserAdminStatus.ACTIVE ? 'Заблокировать' : 'Разблокировать'}
          onClick={
            param.row.status === EUserAdminStatus.ACTIVE
              ? handleBlockUser(param.id.toString())
              : handleUnlockUser(param.id.toString())
          }
        />
      </Tooltip>,
      <Tooltip title="Редактировать">
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Редактировать"
          onClick={handleEditUser(param.id)}
        />
      </Tooltip>,
    ];
  };

  const renderStatus = (params: GridRenderCellParams<EUserAdminStatus>) => {
    if (!params.value) {
      return <span />;
    }

    const color = params.value === EUserAdminStatus.ACTIVE ? 'primary' : 'error';
    const { statusName } = initialUserAdminStatus.find(({ statusId }) => statusId === params.value);

    return <Chip label={statusName} color={color} />;
  };

  const renderRole = (params: GridRenderCellParams<TypeUserAdminRole>) => {
    if (isEmpty(params.value)) {
      return '';
    }

    const role = initialUserAdminRole.find(({ roleId }) => roleId === params.value.roleId);
    const roleName = !isEmpty(role) && has(role, 'roleName') ? role.roleName : params.value.roleId;

    return roleName;
  };

  const rows = useMemo(() => getRows(users), [users]);

  type Row = typeof rows[number];

  const columns = useMemo<GridColumns<Row>>(
    () => getColumns(renderActions, renderStatus, renderRole),
    [users]
  );

  const rowsPerPageOptions = [20];
  const pageSize = 20;
  const rowCount = totalPages >= pageSize ? 10 * totalPages : pageSize;

  return (
    <Container disableGutters>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
          disableSelectionOnClick
          hideFooterSelectedRowCount
          disableColumnMenu
          columns={columns}
          rows={rows}
          pagination={true}
          paginationMode={'server'}
          onPageChange={param => setPage(param)}
          loading={isLoading}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
        />
      </div>

      <BlockUserDialog
        userId={currentUserId}
        status={EUserAdminStatus.BANNED}
        open={showBlockUserModal}
        onClose={handleCloseBlockUserModal}
        message="Вы уверены, что хотите заблокировать пользователя?"
        successMessage="Пользователь заблокирован"
        actionButtonName="Заблокировать"
        dataTestId="block-user-dialog"
      />

      <BlockUserDialog
        userId={currentUserId}
        status={EUserAdminStatus.ACTIVE}
        open={showUnlockUserModal}
        onClose={handleCloseUnlockUserModal}
        message="Вы уверены, что хотите разблокировать пользователя?"
        successMessage="Пользователь разблокирован"
        actionButtonName="Разблокировать"
        dataTestId="unlock-user-dialog"
      />
    </Container>
  );
});
