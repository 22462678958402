import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from './theme';
import { AuthorizationRouter } from './authorization/AuthorizationRouter';
import { AdminRouter } from './admin/AdminRouter';
import { ApplicationRoutes } from './routes';

export const App: FC = observer(() => {
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path={ApplicationRoutes.Authorization} component={AuthorizationRouter} />
        <Route path={ApplicationRoutes.Admin} component={AdminRouter} />
      </Switch>
    </ThemeProvider>
  );
});
