import { lazyInject, provide, Axios, TypeApiRequest, TypeApiResponse } from '../shared/utils';
import { AlertsTypesStore } from '../stores/alertsTypes/alertsTypes.store';
import { AlertsStore } from '../stores/alerts/alerts.store';
import { TASK_NOT_FOUND, TASK_NOT_FOUND_TEXT } from '../../constants/error'
import { TypeAlert } from '../../api/models/alert.model'

@provide.singleton()
export class AlertsController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(AlertsStore)
  protected alertsStore: AlertsStore;

  @lazyInject(AlertsTypesStore)
  protected alertsTypesStore: AlertsTypesStore;

  setPage = (v: number) => {
    this.alertsStore.setPage(v);
  };

  getAlertName = async (alert: TypeAlert) => {
    if (alert.error == TASK_NOT_FOUND) {
      alert.name = TASK_NOT_FOUND_TEXT
    } else {
      const { operationInfo, assignee } = await this.axios.api.getAlertNameById({alertId: alert.taskUpdate.id});
      alert.name = operationInfo.name
      alert.fullName = assignee.fullName
    }
    return alert
  }

  fetchAlerts = async () => {
    this.alertsStore.setLoading(true);

    try {
      const { content, totalPages } = await this.axios.api.getAlerts({});
      const alertsWithNames = await Promise.all(content.map(async (alert) => {
        const alertWithName = await this.getAlertName(alert)
        return alertWithName
      }));
      this.alertsStore.setAlerts(alertsWithNames, totalPages);
    } catch (e) {
      this.alertsStore.error = e.message;
      this.alertsStore.clearAlerts();
    } finally {
      this.alertsStore.setLoading(false);
      this.alertsStore.setLoaded(true);
    }
  };

  fetchAlert = async (
    alertId: TypeApiRequest<'getAlertById'>
  ): Promise<TypeApiResponse<'getAlertById'>> => {
    this.alertsStore.setLoading(true);
    try {
      const alert = await this.axios.api.getAlertById(alertId, { omit: ['alertId'] })

      const alertWithName = await this.getAlertName(alert)

      this.alertsStore.setAlert(alertWithName);
      return alertWithName
    } catch (e) {
      console.log('fetchAlert e.message ', e.message)
      return Promise.reject(e);
    } finally {
      this.alertsStore.setLoading(false);
      this.alertsStore.setLoaded(true);
    }
  };

  changeAlertStatus = (
    payload: TypeApiRequest<'changeAlertStatus'>
  ): Promise<TypeApiResponse<'changeAlertStatus'>> => {
    return this.axios.api
      .changeAlertStatus(payload)
      .then(response => {
        this.alertsStore.setAlert({...this.alertsStore.alert, status: response.status});
        return response;
      })
      .catch(e => {
        console.log('changeAlertStatus e.message ', e.message)
        return Promise.reject(e);
      });
  };
}
