import { TypeApiRoute } from '../../models/type.api.request';
import { TypeAlert } from '../../models/alert.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = {
  search?: string;
};

type TypeResponse = { content: TypeAlert[] } & TypeResponsePageable;

type TypeError = {};

export const getAlerts: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: `/api/as-fields/queue/task/admin/list`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
