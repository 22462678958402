import { useEffect, useMemo, useState } from 'react';
import { Button, PhoneInput, Link as LinkFarmik } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { SignInViaPhoneStore } from '../../stores/sign-in.via-phone.store';
import { useStore } from '../../../../../shared/utils';
import { ApplicationRoutes } from '../../../../../routes';
import { SmsCode } from '../../../../../shared/components/SmsCode';
import { BackButton } from '../BackButton';

import { Wrapper, HeaderWrapper, Heading, FormContent, ButtonWrapper, FieldWrapper } from './style';

type PhoneSignInProps = {
  onChangeType: () => void;
};

export const PhoneSignIn = observer(({ onChangeType }: PhoneSignInProps) => {
  const {
    currentState,
    setFieldValue,
    reset,
    sendCodeToPhone,
    code,
    phone,
    seconds,
    verifyCode,
    isAllowedToRequestCode,
    isCodeWasValid,
    isVerifyCodeButtonAvailable,
    setCurrentState,
  } = useStore(SignInViaPhoneStore);

  const history = useHistory();

  const setters = useMemo(
    () => ({
      setPhone: setFieldValue('phone'),
      setCode: setFieldValue('code'),
    }),
    []
  );

  const [phoneResetRnd, setPhoneResetRnd] = useState<null | undefined | number>();

  useEffect(
    () => () => {
      reset();
    },
    [reset]
  );

  useEffect(() => {
    if (currentState === 'first_step') {
      reset();
    }
  }, [currentState]);

  useEffect(() => {
    if (isCodeWasValid) {
      history.push(ApplicationRoutes.Admin);
    }
  }, [history, isCodeWasValid]);

  const handleSendCode = (e: any) => {
    e.preventDefault();
    if (!isAllowedToRequestCode) {
      return;
    }
    sendCodeToPhone();
  };

  const handleVerifyCode = (e: any) => {
    e.preventDefault();
    verifyCode();
  };

  const renderFormContent = (v: 'first_step' | 'second_step') =>
    v === 'first_step' ? (
      <form onSubmit={handleSendCode}>
        <FieldWrapper>
          <PhoneInput
            dataTestId="login-phone-input"
            onChange={setters.setPhone}
            label="Номер телефона"
            mask="(999) 999 99 99"
            placeholder="+7 (123) 456 78 90"
            error={phone.error}
            resetRnd={phoneResetRnd}
          />
        </FieldWrapper>
        <ButtonWrapper>
          <Button
            dataTestId="login-next-button"
            color="primary"
            type="submit"
            disabled={!isAllowedToRequestCode}
          >
            Далее
          </Button>
        </ButtonWrapper>
      </form>
    ) : (
      <form onSubmit={handleVerifyCode}>
        <FieldWrapper>
          <PhoneInput
            dataTestId="login-phone-input"
            onChange={setters.setPhone}
            label="Номер телефона"
            mask="999 999 99 99"
            error={phone.error}
            disabled={true}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SmsCode
            error={code.error}
            label="Код подтверждения"
            placeholder="000000"
            value={code.value}
            seconds={seconds}
            onSendCodeAgain={sendCodeToPhone}
            onChange={setters.setCode}
          />
        </FieldWrapper>

        <ButtonWrapper>
          <Button
            color="primary"
            type="submit"
            disabled={!isVerifyCodeButtonAvailable}
            dataTestId="login-sms-next-button"
          >
            Войти
          </Button>
        </ButtonWrapper>
      </form>
    );

  const handleBackButton = () => {
    setPhoneResetRnd(Math.random());
    reset();
    setCurrentState('first_step');
  };

  const renderBackButton = (v: boolean) => v && <BackButton onClick={handleBackButton} />;

  return (
    <Wrapper>
      {renderBackButton(currentState === 'second_step')}
      <HeaderWrapper>
        <Heading>Вход</Heading>
        <LinkFarmik
          dataTestId="login-loginByEmail-button"
          onClick={onChangeType}
          label="Войти по e-mail"
          color="primary"
          underlined
        />
      </HeaderWrapper>

      <FormContent>{renderFormContent(currentState)}</FormContent>
    </Wrapper>
  );
});
