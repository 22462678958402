export enum EEmployeeStatus {
  ACTIVE = 'ACTIVE',
  FIRED = 'FIRED',
}

export enum EEmployeeRole {
  OWNER = 'OWNER',
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
}

export type TypeEmployeeStatus = {
  statusId: EEmployeeStatus;
  statusName: string;
};
export type TypeEmployeeRole = {
  roleId: EEmployeeRole;
  roleName: string;
};

interface Avatar {
  id: string;
  fileName: string;
  fileContentType: string;
  fileSize: number;
  downloadUrl: string;
}

type phoneNumberStatus = {
  statusId: string;
  statusName: string;
};

type Phone = {
  phoneNumberCode: string;
  phoneNumber: string;
  phoneNumberStatus: phoneNumberStatus;
};

type RoleInfo = {
  id: string;
  organizationId: string;
  name: string;
  isOwner: boolean;
  isDefault: boolean;
  creationDate: string;
};

type Gender = {
  genderEnum: string;
  genderShort: string;
  genderName: string;
};

export type TypeEmployee = {
  id: string;
  userId: string;
  userName: string;
  email: string;
  phone: Phone;
  role: EEmployeeRole;
  roleInfo: RoleInfo;
  status: EEmployeeStatus;
  gender: Gender;
  birthDate: string;
  organizationJoinDate: string;
  avatar: Avatar;
};

export const initialEmployeeStatus: TypeEmployeeStatus[] = [
  {
    statusId: EEmployeeStatus.ACTIVE,
    statusName: 'Активный',
  },
  {
    statusId: EEmployeeStatus.FIRED,
    statusName: 'Уволен',
  },
];

export const initialEmployeeRole: TypeEmployeeRole[] = [
  {
    roleId: EEmployeeRole.OWNER,
    roleName: 'Владелец',
  },
  {
    roleId: EEmployeeRole.EMPLOYEE,
    roleName: 'Работник',
  },
  {
    roleId: EEmployeeRole.MANAGER,
    roleName: 'Менеджер',
  },
];
