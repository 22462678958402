import { TypeApiRoute } from '../../models/type.api.request';
import { EUserAdminStatus, EUserAdminRole } from '../../models/user.admin.model';

type TypeRequest = {
  userId: string;
  firstName: string;
  lastName: string;
  secondName?: string;
  gender?: string;
  dateOfBirth: string;
  avatar?: string;
  phoneNumber?: string;
  email?: string;
  bio?: string;
  specializationId?: number;
  agreement?: boolean;
  status?: EUserAdminStatus;
  role?: EUserAdminRole;
  includedInStats?: boolean;
};

type TypeResponse = {};

type TypeError = {};

export const updateUserByAdmin: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: (request: TypeRequest) => `/api/da-profile/users/${request.userId}`,
  method: 'PUT',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
