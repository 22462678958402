import { createTheme, Theme } from '@mui/material/styles';

import { Colors } from '../constants/colors';

const defaultTheme: Theme = createTheme({
  typography: {
    fontFamily: ['"Proxima Nova"', 'sans-serif'].join(' '),
    fontSize: 14,
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1366,
    },
  },

  palette: {
    primary: {
      light: Colors.lightGreen,
      main: Colors.green,
      dark: Colors.darkGreen,
      contrastText: Colors.white,
    },

    secondary: {
      light: Colors.lightGray,
      main: Colors.gray,
      dark: Colors.darkGray,
      contrastText: Colors.white,
    },

    error: {
      light: Colors.lightRed,
      main: Colors.red,
      dark: Colors.darkRed,
      contrastText: Colors.white,
    },

    warning: {
      light: Colors.lightOrange,
      main: Colors.orange,
      dark: Colors.darkOrange,
      contrastText: Colors.white,
    },

    info: {
      light: Colors.lightBlue,
      main: Colors.blue,
      dark: Colors.darkBlue,
      contrastText: Colors.white,
    },

    success: {
      light: Colors.lightGreen,
      main: Colors.green,
      dark: Colors.darkGreen,
      contrastText: Colors.white,
    },
  },

  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: 1.4,
          transform: 'translate(16px, 12px) scale(1)',
        },

        shrink: {
          transform: 'translate(16px, -7px) scale(0.75)',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },

        input: {
          color: Colors.black,
          fontSize: 14,
          padding: '11.5px 16px',
        },

        notchedOutline: {
          fontSize: '0.9rem',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '4px 16px',
        },

        tag: {
          height: 26,
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.675rem',
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          width: 196,
          flexShrink: 0,
          marginRight: 20,
        },

        paper: ({ theme }) => ({
          width: 196,
          height: 'calc(100% - 116px)',
          boxSizing: 'border-box',
          top: 96,
          left: 'auto',
          zIndex: 1,
          background: Colors.white,
          borderRadius: 16,
          border: 'none',

          '.MuiToolbar-root': {
            minHeight: 69,
            borderBottom: '1px solid',
            borderBottomColor: Colors.darknessGray,
            padding: 0,

            a: {
              display: 'flex',
              alignItems: 'center',
              padding: '32px 0 16px 25px',
              color: '#9AA0AD',
              fontSize: 14,
              fontWeight: 600,

              svg: {
                width: 22,
                height: 22,
              },
            },
          },

          '.MuiList-root': {
            padding: 0,

            a: {
              '.MuiListItem-root': {
                padding: '24px 24px 24px 27px',
                color: Colors.black,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: 1.4285714285714286,

                '&:hover, &:focus': {
                  backgroundColor: Colors.lightnessGray,
                },

                svg: {
                  fontSize: 16,
                  marginRight: 16,
                },
              },

              '&.active .MuiListItem-root': {
                color: Colors.green,
                backgroundColor: Colors.lightnessGray,
              },
            },
          },

          /* [theme.breakpoints.down('xl')]: {
            backgroundColor: 'red',
          }, */
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '40px 60px 12px 60px',

          svg: {
            width: 50,
            height: 50,
            display: 'block',
            margin: '15px auto 23px auto',
          },
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: 60,
          paddingRight: 60,
          paddingBottom: 16,
        },
      },
    },

    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '20px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'left',
          marginBottom: 24,

          '&.divider': {
            paddingBottom: 24,
            borderBottom: '1px solid',
            borderBottomColor: Colors.darknessGray,
          },

          '&.description': {
            color: Colors.black,
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 24,
            lineHeight: '28px',
            marginBottom: 8,
          },
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: 60,
          paddingRight: 60,
          paddingBottom: 40,
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          '&.action-edit': {
            display: 'flex',
            fontSize: 12,
            svg: {
              width: 12,
              height: 12,
              marginLeft: 5,
            },
          },
        },
      },
    },
  },
});

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        border: '1px solid',
        borderColor: Colors.darknessGray,
        borderRadius: 12,
        height: 300,
        maxHeight: 300,
        overflow: 'auto',
      },

      editor: {
        padding: 20,
      },

      placeHolder: {
        padding: 20,
      },

      toolbar: {
        borderBottom: '1px solid',
        borderBottomColor: Colors.darknessGray,
      },
    },
  },
});

export { defaultTheme as theme };
