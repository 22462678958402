import React, { FC, HTMLAttributes, SVGProps, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Container,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Avatar,
  IconButton,
  Divider,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

import { AdminRoutes } from '../../routes';
import { getHeaderMenu } from '../../../shared/menu';

import {
  HeaderContainer,
  AppBarStyled,
  ToolbarStyled,
  AppBarLogoStyled,
  ButtonStyled,
} from './styled';

interface HeaderMenuItemProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  link: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  slug: string;
}

export const HeaderMenu = () => {
  const history = useHistory();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const menuItems = useMemo(() => getHeaderMenu(), []);

  const HeaderMenuItem: FC<HeaderMenuItemProps> = ({ name, link, icon: Icon, slug }) => {
    const match = useRouteMatch(link);
    const isActive = match
      ? (link === AdminRoutes.Main && match.isExact) || link !== AdminRoutes.Main
      : false;
    return (
      <ButtonStyled
        onClick={() => history.push(link)}
        isActive={isActive}
        startIcon={<Icon />}
        data-test-id={`header-menu-item-${slug}`}
        disableRipple
      >
        {name}
      </ButtonStyled>
    );
  };

  return (
    <HeaderContainer data-test-id="header-container">
      <Container fixed disableGutters>
        <AppBarStyled position="relative" data-test-id="app-bar">
          <Container maxWidth="xl">
            <ToolbarStyled disableGutters data-test-id="toolbar">
              <AppBarLogoStyled data-test-id="header-logo" />
              <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                {Object.entries(menuItems).map(([key, item]) => (
                  <HeaderMenuItem key={key} {...item} />
                ))}
              </Box>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Меню профайла">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Перейти в профиль</ListItemText>
                  </MenuItem>

                  <Divider />

                  <MenuItem onClick={handleCloseUserMenu}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Выйти</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            </ToolbarStyled>
          </Container>
        </AppBarStyled>
      </Container>
    </HeaderContainer>
  );
};
