import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleIcon from '@mui/icons-material/People';
import BookIcon from '@mui/icons-material/Book';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GrassIcon from '@mui/icons-material/Grass';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { AdminRoutes, ERegexp } from '../../admin/routes';
import { getLink } from '../utils';

import { MenuItemsProps } from './type';

function getOrganizationMenu(organizationId: string): MenuItemsProps {
  return {
    back: {
      name: 'Вернуться назад',
      link: AdminRoutes.Organizations,
      icon: KeyboardArrowLeftIcon,
      slug: 'organizations-back',
    },

    org: {
      name: 'Организация',
      link: getLink(AdminRoutes.OrganizationRequisites, {
        [organizationId]: ERegexp.ORGANIZATIONID,
      }),
      icon: CorporateFareIcon,
      slug: 'organization-item',
    },

    employes: {
      name: 'Сотрудники',
      link: getLink(AdminRoutes.OrganizationEmployes, { [organizationId]: ERegexp.ORGANIZATIONID }),
      icon: PeopleIcon,
      slug: 'organization-employes',
    },

    invites: {
      name: 'Приглашения',
      link: getLink(AdminRoutes.OrganizationInvites, { [organizationId]: ERegexp.ORGANIZATIONID }),
      icon: BookIcon,
      slug: 'organization-invites',
    },

    news: {
      name: 'Публикации',
      link: getLink(AdminRoutes.OrganizationNews, { [organizationId]: ERegexp.ORGANIZATIONID }),
      icon: NewspaperIcon,
      slug: 'organization-news',
    },

    fields: {
      name: 'Поля',
      link: getLink(AdminRoutes.OrganizationFields, { [organizationId]: ERegexp.ORGANIZATIONID }),
      icon: GrassIcon,
      slug: 'organization-fields',
    },

    roles: {
      name: 'Роли',
      link: getLink(AdminRoutes.OrganizationRoles, { [organizationId]: ERegexp.ORGANIZATIONID }),
      icon: PeopleAltIcon,
      slug: 'organization-roles',
    },
  };
}

export { getOrganizationMenu };
