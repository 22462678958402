import { isEmpty } from 'lodash';

import { TypeApiRequest, TypeApiResponse, lazyInject, provide, Axios } from '../shared/utils';
import { EmployeeStore } from '../stores/employee/employee.store';
import { FilterStore } from '../stores/employee/filter.store';
import { TypeEmployeeStatus, TypeEmployeeRole } from '../../api/models/employee.model';

@provide.singleton()
export class EmployeeController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(EmployeeStore)
  protected employeeStore: EmployeeStore;

  @lazyInject(FilterStore)
  protected filterStore: FilterStore;

  setFilterStatus = (status: TypeEmployeeStatus) => {
    this.filterStore.setFilterStatus(status);
  };

  setFilterRole = (role: TypeEmployeeRole) => {
    this.filterStore.setFilterRole(role);
  };

  setFilterRoleID = (value: string) => {
    this.filterStore.setFilterRoleID(value);
  };

  setFilterNotRoleId = (value: string) => {
    this.filterStore.setFilterNotRoleId(value);
  };

  setFilterFullname = (value: string) => {
    this.filterStore.setFilterFullname(value);
  };

  setFilterContext = (value: string) => {
    this.filterStore.setFilterContext(value);
  };

  fetchEmployees = (
    data: TypeApiRequest<'getOrganizationsUserList'>
  ): Promise<TypeApiResponse<'getOrganizationsUserList'>> => {
    this.employeeStore.setLoading(true);

    const payload = {
      organizationID: data.organizationID,
      status: !isEmpty(this.filterStore.filters.status)
        ? this.filterStore.filters.status.statusId
        : null,
      role: !isEmpty(this.filterStore.filters.role) ? this.filterStore.filters.role.roleId : null,
      roleId: this.filterStore.filters.roleId,
      notRoleId: this.filterStore.filters.notRoleId,
      fullname: this.filterStore.filters.fullname,
      context: this.filterStore.filters.context,
    };

    return this.axios.api
      .getOrganizationsUserList(payload)
      .then(employees => {
        this.employeeStore.setEmployees(employees.content);

        return employees;
      })
      .catch(e => {
        console.error('fetchEmployees e.message', e.message);

        return Promise.reject(e);
      })
      .finally(() => {
        this.employeeStore.setLoading(false);
        this.employeeStore.setLoaded(true);
      });
  };
}
