import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import {
  EEmployeeStatus,
  initialEmployeeStatus,
  initialEmployeeRole,
  TypeEmployeeStatus,
  TypeEmployeeRole,
} from '../../../api/models/employee.model';

type TypeFilters = {
  status: TypeEmployeeStatus;
  role: TypeEmployeeRole;
  roleId: string;
  notRoleId: string;
  fullname: string;
  context: string;
};

@provide.singleton()
export class FilterStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  statusOptions = initialEmployeeStatus;
  rolesOptions = initialEmployeeRole;

  filters: TypeFilters = {
    status: this.statusOptions.find(({ statusId }) => statusId === EEmployeeStatus.ACTIVE),
    role: null,
    roleId: '',
    notRoleId: '',
    fullname: '',
    context: '',
  };

  setFilterStatus = (status: TypeEmployeeStatus) => {
    runInAction(() => {
      this.filters.status = status;
    });
  };

  setFilterRole = (role: TypeEmployeeRole) => {
    runInAction(() => {
      this.filters.role = role;
    });
  };

  setFilterRoleID = (value: string) => {
    runInAction(() => {
      this.filters.roleId = value;
    });
  };

  setFilterNotRoleId = (value: string) => {
    runInAction(() => {
      this.filters.notRoleId = value;
    });
  };

  setFilterFullname = (value: string) => {
    runInAction(() => {
      this.filters.fullname = value;
    });
  };

  setFilterContext = (value: string) => {
    runInAction(() => {
      this.filters.context = value;
    });
  };

  clearFilter = () => {
    runInAction(() => {
      this.filters.status = this.statusOptions.find(
        ({ statusId }) => statusId === EEmployeeStatus.ACTIVE
      );
      this.filters.role = null;
      this.filters.roleId = '';
      this.filters.notRoleId = '';
      this.filters.fullname = '';
      this.filters.context = '';
    });
  };
}
