import { TypeApiRoute } from '../../models/type.api.request';
import { TypeEmployee, EEmployeeStatus, EEmployeeRole } from '../../models/employee.model';
import { TypeResponsePageable } from '../../models/response.pageable.model';

type TypeRequest = {
  organizationID: string;
  status?: EEmployeeStatus;
  role?: EEmployeeRole;
  roleId?: string;
  notRoleId?: string;
  fullname?: string;
  context?: string;
};

type TypeResponse = { content: TypeEmployee[] } & TypeResponsePageable;

type TypeError = {};

export const getOrganizationsUserList: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: '/api/da-profile/organizations/userList',
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
