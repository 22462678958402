import { FC, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useParams } from 'react-router';

import { AdminRoutes } from '../../routes';
import { Layout } from '../../components';
import { getOrganizationMenu } from '../../../shared/menu';

import {
  Organization,
  OrganizationEmployes,
  OrganizationInvites,
  OrganizationNews,
  OrganizationFields,
  OrganizationRoles,
} from './pages';

export const OrganizationRouter: FC<any> = () => {
  const { organizationId } = useParams<{ organizationId?: string }>();
  const menuItems = useMemo(() => getOrganizationMenu(organizationId), [organizationId]);

  return (
    <Layout menuItems={menuItems}>
      <Switch>
        <Route exact path={AdminRoutes.OrganizationRequisites} component={Organization} />
        <Route exact path={AdminRoutes.OrganizationEmployes} component={OrganizationEmployes} />
        <Route exact path={AdminRoutes.OrganizationInvites} component={OrganizationInvites} />
        <Route exact path={AdminRoutes.OrganizationNews} component={OrganizationNews} />
        <Route exact path={AdminRoutes.OrganizationFields} component={OrganizationFields} />
        <Route exact path={AdminRoutes.OrganizationRoles} component={OrganizationRoles} />
        <Redirect to={AdminRoutes.OrganizationRequisites} />
      </Switch>
    </Layout>
  );
};
