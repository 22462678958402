import { makeAutoObservable, runInAction } from 'mobx';

import { lazyInject, provide, Axios } from '../../shared/utils';
import { TypeEmployee } from '../../../api/models/employee.model';

import { FilterStore } from './filter.store';

@provide.singleton()
export class EmployeeStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(FilterStore)
  filterStore: FilterStore;

  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loaded = false;

  employees: TypeEmployee[] = [];
  employee: TypeEmployee = {} as TypeEmployee;

  get isLoading() {
    return this.loading || !this.loaded;
  }

  get isLoaded() {
    return !this.loading && this.loaded;
  }

  setLoading = (v: boolean) => {
    runInAction(() => {
      this.loading = v;
    });
  };

  setLoaded = (v: boolean) => {
    runInAction(() => {
      this.loaded = v;
    });
  };

  setEmployees = (employees: TypeEmployee[]) => {
    runInAction(() => {
      this.employees = employees;
    });
  };

  setEmployee = (employee: TypeEmployee) => {
    runInAction(() => {
      this.employee = employee;
    });
  };

  clearEmployees = () => {
    this.setEmployees([]);
  };

  clearEmployee = () => {
    this.setEmployee({} as TypeEmployee);
  };
}
