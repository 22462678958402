import { TypeApiRoute } from '../../models/type.api.request';
import { TypeCheckList } from '../../models/check.list.model';

type TypeRequest = {
    checkListId: string;
};
  
type TypeResponse = TypeCheckList;

type TypeError = {};

export const getCheckListById: TypeApiRoute & {
  request: TypeRequest;
  response: TypeResponse;
  error: TypeError;
} = {
  url: params => `/api/as-fields/queue/checkListInstance/admin/${params.checkListId}`,
  method: 'GET',
  headers: {},
  request: {} as TypeRequest,
  response: {} as TypeResponse,
  error: {} as TypeError,
};
