import { FC } from 'react';
import { observer } from 'mobx-react';

import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';
import { Layout } from '../../../../components';

import { OrganizationsFilter, OrganizationsTable } from './components';

export const OrganizationsList: FC = observer(() => {
  return (
    <Layout>
      <MainContainer>
        <HeaderWrapper>
          <TopSection>
            <H1>Организации</H1>
          </TopSection>
        </HeaderWrapper>

        <MainBody>
          <OrganizationsFilter />
          <OrganizationsTable />
        </MainBody>
      </MainContainer>
    </Layout>
  );
});
