import { FC, HTMLAttributes, ReactNode } from 'react';

import { MenuItemsProps } from '../../../shared/menu/type';
import { getMainMenu } from '../../../shared/menu';
import { Sidebar } from '../';

import { LayoutWrapper, LayoutContent, Main } from './style';

export interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  menuItems?: MenuItemsProps;
}

export const Layout: FC<LayoutProps> = ({ children, menuItems = getMainMenu() }) => (
  <LayoutWrapper>
    <LayoutContent>
      <Sidebar menuItems={menuItems} />
      <Main component="main">{children}</Main>
    </LayoutContent>
  </LayoutWrapper>
);
