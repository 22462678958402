import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AdminRoutes } from '../../routes';

import {
  UsersList,
  UserCreate,
  UserEdit,
  User,
  UserOrganizations,
  UserNews,
  UserJournal,
  UserExpertises,
  UserInterests,
} from './pages';

export const UsersRouter: FC<any> = () => {
  return (
    <Switch>
      <Route exact path={AdminRoutes.UserCreate} component={UserCreate} />
      <Route exact path={AdminRoutes.UserEdit} component={UserEdit} />
      <Route exact path={AdminRoutes.UserItem} component={User} />
      <Route exact path={AdminRoutes.UserOrganizations} component={UserOrganizations} />
      <Route exact path={AdminRoutes.UserNews} component={UserNews} />
      <Route exact path={AdminRoutes.UserJournal} component={UserJournal} />
      <Route exact path={AdminRoutes.EditExpertises} component={UserExpertises} />
      <Route exact path={AdminRoutes.EditInterests} component={UserInterests} />
      <Route path={AdminRoutes.Users} component={UsersList} />
      <Redirect to={AdminRoutes.Users} />
    </Switch>
  );
};
