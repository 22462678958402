import { lazyInject, provide, Axios, TypeApiRequest, TypeApiResponse } from '../shared/utils';
import { CheckListsTypesStore } from '../stores/checkListTypes/checkListTypes.store';
import { CheckListStore } from '../stores/checkLists/check.list.store';

@provide.singleton()
export class CheckListsController {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(CheckListStore)
  protected checkListStore: CheckListStore;

  @lazyInject(CheckListsTypesStore)
  protected checkListsTypesStore: CheckListsTypesStore;

  setPage = (v: number) => {
    this.checkListStore.setPage(v);
  };

  fetchCheckLists = async () => {
    this.checkListStore.setLoading(true);

    try {
      const { content, totalPages } = await this.axios.api.getCheckLists({});
      this.checkListStore.setCheckLists(content, totalPages);
    } catch (e) {
      this.checkListStore.error = e.message;
      this.checkListStore.clearCheckLists();
    } finally {
      this.checkListStore.setLoading(false);
      this.checkListStore.setLoaded(true);
    }
  };

  fetchCheckList = async (
    checkListId: TypeApiRequest<'getCheckListById'>
  ): Promise<TypeApiResponse<'getCheckListById'>> => {
    this.checkListStore.setLoading(true);
    try {
      const checkList = await this.axios.api.getCheckListById(checkListId, { omit: ['checkListId'] })

      this.checkListStore.setCheckList(checkList);
      return checkList
    } catch (e) {
        this.checkListStore.error = e.message;
    } finally {
      this.checkListStore.setLoading(false);
      this.checkListStore.setLoaded(true);
    }
  };

  changeCheckListStatus = async (
    payload: TypeApiRequest<'changeCheckListStatus'>
  ): Promise<TypeApiResponse<'changeCheckListStatus'>> => {
    this.checkListStore.setLoading(true);
    try {
      const checkList = await this.axios.api.changeCheckListStatus(payload)

      this.checkListStore.setCheckList({...this.checkListStore.checkList, status: checkList.status});
      return checkList
    } catch (e) {
        this.checkListStore.error = e.message;
    } finally {
      this.checkListStore.setLoading(false);
      this.checkListStore.setLoaded(true);
    }
  };
}
