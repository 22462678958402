import { makeAutoObservable, runInAction } from 'mobx';
import { Moment } from 'moment';

import { lazyInject, provide, Axios } from '../../shared/utils';
import {
  TypeUserAdminRole,
  initialUserAdminRole,
  TypeUserAdminStatus,
  initialUserAdminStatus,
  TypeUserAdminGender,
  initialUserAdminGender,
  EUserAdminStatus,
} from '../../../api/models/user.admin.model';

type TypeFilters = {
  userId: string;
  fullName: string;
  phoneNum: string;
  email: string;
  regDateFrom: Moment;
  regDateTo: Moment;
  roles: TypeUserAdminRole[];
  statuses: TypeUserAdminStatus;
  genders: TypeUserAdminGender[];
  context: string;
};

@provide.singleton()
export class FilterStore {
  @lazyInject(Axios)
  protected axios: Axios;

  constructor() {
    makeAutoObservable(this);
  }

  rolesOptions = initialUserAdminRole;
  statusesOptions = initialUserAdminStatus;
  gendersOptions = initialUserAdminGender;

  filters: TypeFilters = {
    userId: '',
    fullName: '',
    phoneNum: '',
    email: '',
    regDateFrom: null,
    regDateTo: null,
    roles: [],
    statuses: this.statusesOptions.find(({ statusId }) => statusId === EUserAdminStatus.ACTIVE),
    genders: [],
    context: '',
  };

  setFilterUserId = (value: string) => {
    runInAction(() => {
      this.filters.userId = value;
    });
  };

  setFilterFullName = (value: string) => {
    runInAction(() => {
      this.filters.fullName = value;
    });
  };

  setFilterPhoneNum = (value: string) => {
    runInAction(() => {
      this.filters.phoneNum = value;
    });
  };

  setFilterEmail = (value: string) => {
    runInAction(() => {
      this.filters.email = value;
    });
  };

  setFilterRegDateFrom = (date: Moment) => {
    runInAction(() => {
      this.filters.regDateFrom = date;
    });
  };

  setFilterRegDateTo = (date: Moment) => {
    runInAction(() => {
      this.filters.regDateTo = date;
    });
  };

  setFilterRoles = (roles: TypeUserAdminRole[]) => {
    runInAction(() => {
      this.filters.roles = roles;
    });
  };

  setFilterStatuses = (statuses: TypeUserAdminStatus) => {
    runInAction(() => {
      this.filters.statuses = statuses;
    });
  };

  setFilterGenders = (genders: TypeUserAdminGender[]) => {
    runInAction(() => {
      this.filters.genders = genders;
    });
  };
}
