import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Button, Grid, Autocomplete, TextField, CircularProgress } from '@mui/material';

import { useStore } from '../../../../../shared/utils';
import { OrganizationsController } from '../../../../../controllers/organizations.controller';
import { OrganizationsStore } from '../../../../../stores/organizations/organizations.store';
import { OrganizationsTypesStore } from '../../../../../stores/organizationsTypes/organizationsTypes.store';
import { getValues } from '../../utils';
import { EFormFields, TypeOrganizationType } from '../../../../../../api/models/organization.model';
import { Uploader } from '../../../../../shared/components/Uploader';
import { TextMaskField } from '../../../../../shared/components/TextMaskField';
import { MainContainer, MainBody, HeaderWrapper, TopSection, H1 } from '../../../../style';

import { EmployeesDialog } from './components';

export const Organization: FC = observer(() => {
  const { fetchOrganization, fetchOrganizationsTypes } = useStore(OrganizationsController);
  const { organization, isLoaded, isLoading: isLoadingOrganization } = useStore(OrganizationsStore);
  const { organizationsTypes, isLoading: isLoadingOrganizationType } = useStore(
    OrganizationsTypesStore
  );

  const [showEmployeesModal, setShowEmployeesModal] = useState<boolean>(false);

  const { organizationId } = useParams<{ organizationId?: string }>();

  const values = useMemo(() => getValues(organization), [organization]);

  useEffect(() => {
    fetchOrganizationsTypes();
    fetchOrganization({ organizationId });
  }, []);

  const handleShowEmployeesModal = useCallback(() => {
    setShowEmployeesModal(true);
  }, []);
  const handleCloseEmployeesModal = useCallback(() => {
    setShowEmployeesModal(false);
  }, []);

  if (isLoadingOrganization) {
    return <p>Загрузка</p>;
  }

  return (
    <MainContainer>
      {isLoaded && (
        <>
          <HeaderWrapper>
            <TopSection>
              <H1>Реквезиты организации</H1>
            </TopSection>
          </HeaderWrapper>

          <MainBody>
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Uploader fileId={values.logo} disabled />
                </Grid>

                <Grid item xs={10} container spacing={2}>
                  <Grid item xs={2}>
                    <Autocomplete
                      fullWidth
                      value={values[EFormFields.organizationType]}
                      options={organizationsTypes}
                      getOptionLabel={(option: TypeOrganizationType) => option.OrgType}
                      isOptionEqualToValue={(option, value) => option.orgTypeId === value.orgTypeId}
                      loading={isLoadingOrganizationType}
                      loadingText="Загрузка..."
                      noOptionsText="Типов организации не найдено"
                      disabled
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          name={EFormFields.organizationType}
                          placeholder="Ввыберите тип организации"
                          label="Типы организаций"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingOrganizationType ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={values[EFormFields.name]}
                      placeholder="Введите название организации"
                      label="Название организации *"
                      disabled
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      value={values[EFormFields.inn]}
                      placeholder="Введите ИНН"
                      label="ИНН"
                      disabled
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      value={values[EFormFields.ogrn]}
                      placeholder="Введите ОГРН"
                      label="ОГРН"
                      disabled
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      value={values[EFormFields.okved]}
                      placeholder="Введите ОКВЭД"
                      label="ОКВЭД"
                      disabled
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      value={values[EFormFields.okpo]}
                      placeholder="Введите ОКПО"
                      label="ОКПО"
                      disabled
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      value={values[EFormFields.kpp]}
                      placeholder="Введите КПП"
                      label="КПП"
                      disabled
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      value={values[EFormFields.addressJur]}
                      placeholder="Введите юридический адрес"
                      label="Юридический адрес"
                      disabled
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      value={values[EFormFields.addressFact]}
                      placeholder="Введите фактический адрес"
                      label="Фактический адрес"
                      disabled
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={6} alignSelf="flex-end">
                    <TextField
                      fullWidth
                      value={values[EFormFields.phone]}
                      placeholder="Введите номер телефона"
                      label="Номер телефона"
                      variant="outlined"
                      inputProps={{
                        mask: '+0 (000) 000-00-00',
                      }}
                      InputProps={{
                        inputComponent: TextMaskField as any,
                      }}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={6} alignSelf="flex-end">
                    <TextField
                      fullWidth
                      value={values[EFormFields.email]}
                      placeholder="Введите E-mail пользователя"
                      label="E-mail"
                      variant="outlined"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      value={values[EFormFields.contact]}
                      placeholder="Введите представителя"
                      label="Представитель"
                      disabled
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button variant="outlined" color="primary" onClick={handleShowEmployeesModal}>
                      Изменить основателя
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <EmployeesDialog
              organizationID={organizationId}
              open={showEmployeesModal}
              onClose={handleCloseEmployeesModal}
              dataTestId="employees-dialog"
            />
          </MainBody>
        </>
      )}
    </MainContainer>
  );
});
