import { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { Container } from '@mui/material';
import { GridColumns, GridRowId, DataGrid } from '@mui/x-data-grid';

import { AlertsStore } from '../../../../../../../stores/alerts/alerts.store';
import { AlertsController } from '../../../../../../../controllers/alerts.controller';
import { useStore, getLink } from '../../../../../../../shared/utils';
import { AdminRoutes, ERegexp } from '../../../../../../routes';

import { getColumns, getRows } from './utils';

export const AlertsTable: FC = observer(() => {
  const history = useHistory();

  const { alerts, setPage, page, totalPages, isLoading } = useStore(AlertsStore);
  const { fetchAlerts } = useStore(AlertsController);

  const handleOpenAlertTask = (id: GridRowId) => {
    history.push(getLink(AdminRoutes.AlertTask, { [id]: ERegexp.ALERTID }));
  };

  useEffect(() => {
    fetchAlerts();
  }, [page]);

  const rows = useMemo(() => getRows(alerts), [alerts]);

  type Row = typeof rows[number];

  const columns = useMemo<GridColumns<Row>>(() => getColumns(), []);

  return (
    <Container disableGutters>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
          disableColumnMenu
          hideFooterSelectedRowCount
          columns={columns}
          rows={rows}
          onRowClick={e => handleOpenAlertTask(e.id)}
          pagination={true}
          paginationMode={'server'}
          onPageChange={param => setPage(param)}
          loading={isLoading}
          rowsPerPageOptions={[20]}
          page={page}
          pageSize={20}
          rowCount={10 * totalPages}
        />
      </div>
    </Container>
  );
});
