import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AdminRoutes } from '../../routes';

import { OrganizationsList } from './pages';
import { OrganizationRouter } from './OrganizationRouter';

export const OrganizationsRouter: FC<any> = () => {
  return (
    <Switch>
      <Route path={AdminRoutes.OrganizationItem} component={OrganizationRouter} />
      <Route path={AdminRoutes.Organizations} component={OrganizationsList} />
      <Redirect to={AdminRoutes.Organizations} />
    </Switch>
  );
};
