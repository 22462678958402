import { styled } from '@mui/material/styles';

import { Colors } from '../../../../../../constants/colors';

export const Wrapper = styled('div')`
  position: relative;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background: ${Colors.white};
  padding-top: 44px;
  padding-bottom: 28px;

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (min-width: 768px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

export const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 0.5px solid #e9e6f0;

  padding-bottom: 24px;
  margin-bottom: 20px;
`;

export const Heading = styled('p')`
  color: #151f32;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 36px;
  }
`;

export const FormContent = styled('div')`
  padding-bottom: 27px;
`;

export const FieldWrapper = styled('div')`
  text-align: left;
  width: 100%;
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled('div')`
  width: 100%;
  text-align: center;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;

export const FooterWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const FooterText = styled('p')`
  color: ${Colors.darkGray};
  margin: 0;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (min-width: 1366px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
