import { FC, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { useStore } from '../../../../../../../shared/utils';
import { UsersController } from '../../../../../../../controllers/users.controller';
import { UsersStore } from '../../../../../../../stores/users/users.store';
import { EUserAdminStatus } from '../../../../../../../../api/models/user.admin.model';
import { ReactComponent as Success } from '../../../../../../../shared/assets/images/success.svg';
import { ReactComponent as Cancel } from '../../../../../../../shared/assets/images/cancel.svg';

type Props = {
  userId: string;
  status: EUserAdminStatus;
  open: boolean;
  onClose: () => void;
  message: string;
  successMessage: string;
  closeButtonName?: string;
  actionButtonName: string;
  successButtonName?: string;
  className?: string;
  dataTestId?: string;
};

export const BlockUserDialog: FC<Props> = ({
  userId,
  status,
  open,
  onClose,
  message,
  successMessage,
  closeButtonName = 'Отменить',
  actionButtonName,
  successButtonName = 'Хорошо',
  className,
  dataTestId = '',
}) => {
  const { updateUser } = useStore(UsersController);
  const { setUsers, users, totalPages } = useStore(UsersStore);
  const [success, setSuccess] = useState<boolean>(false);

  const handleBlockUser = useCallback(() => {
    const data = users.find(item => item.id === userId);
    // Нужна ручка для смены статуса по userId
    const payload = {
      userId,
      dateOfBirth: data.dateOfBirth,
      firstName: 'Терехов',
      lastName: 'Владимир',
      secondName: data.secondName,
      status,
    };

    updateUser(payload).then(() => {
      const newUsers = users.map(item => {
        if (item.id !== data.id) {
          return item;
        }

        return { ...item, status };
      });

      setUsers(newUsers, totalPages);
      setSuccess(true);
    });
  }, [userId]);

  return (
    <Dialog open={open} onClose={onClose} className={className} data-test-id={dataTestId}>
      {!success ? (
        <>
          <DialogTitle>
            <div style={{ display: 'block', textAlign: 'center' }}>
              <Cancel style={{ width: '38px', height: '57px' }} />
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{closeButtonName}</Button>
            <Button onClick={handleBlockUser}>{actionButtonName}</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            <div style={{ display: 'block', textAlign: 'center' }}>
              <Success style={{ width: '38px', height: '57px' }} />
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{successMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{successButtonName}</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
